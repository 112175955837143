export default function Youtube() {
  return (
    <>
      <svg
        id="youtube-link"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
      >
        <g
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
        >
          <path d="M14.862 4.463c-.407-.702-.848-.831-1.747-.88-.898-.059-3.157-.083-5.113-.083-1.96 0-4.22.024-5.117.083-.897.05-1.339.178-1.75.88C.716 5.165.5 6.372.5 8.498v.008c0 2.117.216 3.333.635 4.026.41.701.852.829 1.75.888.897.05 3.156.08 5.117.08 1.956 0 4.215-.03 5.114-.08.899-.059 1.34-.187 1.747-.888.424-.694.638-1.91.638-4.026v-.008c-.001-2.126-.214-3.333-.639-4.035z" />
          <path d="M6.5 11V6l4 2.5z" />
        </g>
      </svg>
    </>
  );
}
